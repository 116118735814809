.invoice {
  margin-bottom: 40px;
}

.invoice-row {
  border-bottom: 2px solid $light-gray;
  padding: 15px 0;
  font-size: 14px;
}

.invoice-row:last-child {
  border-bottom-width: 0;
}

.invoice-value {
  float: right;
  font-size: 16px;
}
