/* general */
@import "variables.css";
@import "common.css";
@import "typography.css";

/* layouts */
@import "layouts/storytelling.css";
@import "layouts/user-message.css";
@import "layouts/trail.css";

/* components */
@import "components/icon.css";
@import "components/button.css";
@import "components/modal.css";
@import "components/form.css";
@import "components/avatar-slide.css";
@import "components/list-box.css";
@import "components/invoice.css";
@import "components/storytelling.css";
@import "components/clock-countdown.css";
@import "components/knowledge-area-slide.css";
@import "components/question.css";
@import "components/question-alternative.css";
@import "components/buttons-container.css";
@import "components/question-slide.css";
@import "components/trail.css";
@import "components/questions-selector.css";
@import "components/exams.css";
@import "components/processing.css";

/* pages */
@import "pages/home.css";
@import "pages/sign-up.css";
@import "pages/payment.css";
@import "pages/subscription-offer.css";
@import "pages/bag.css";
@import "pages/edit-profile.css";
@import "pages/manual.css";
@import "pages/performance.css";
@import "pages/exam-answer.css";
@import "pages/exam-finalized.css";
@import "pages/satellite.css";
@import "pages/exam-language-chooser.css";
@import "pages/exam-rules.css";
@import "pages/upload-essay.css";
@import "pages/essay-topic.css";

.md-game-alert-tip {
  color: #f13a30;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  border: 1px dashed;
  padding: 12px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  #game-station #header-back + div {
    position: relative;
  }
}

.match-warn-tip {
  padding: 12px 0;
  font-size: 16px;
}

.GameDetails-fixedTitle {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin: 35px 0;
  color: purple;
}

.GameDetails-title {
  font-size: 16px;
  line-height: 1.4em;
  color: #ff8cba;
  font-weight: 700;
  margin-bottom: 15px;
}

.GameDetails-text {
  font-size: 16px;
  line-height: 1.4em;
}

.GameDetails-textSection,
.GameDetails-instructionItem {
  margin-bottom: 30px;
}

.GameDetails-textSection p {
  margin-bottom: 0;
}

.GameDetails-orderedList {
  font-size: 16px;
  line-height: 1.6em;
  list-style-position: inside;
  padding-left: 25px;
  margin-top: 15px;
}

.GameDetails-ruleList {
  font-size: 16px;
  line-height: 1.6em;
  list-style-position: inside; 
}

.GameDetails-ruleList li + li {
  margin-top: 20px;
}

.GameDetails-text-description {
  font-weight: 500;
  margin-bottom: 15px;
}

.game-details-only-in-mobile {
  display: none;
}

.game-details-only-in-mobile img {
  max-width: 50%;
}

@media (max-width: 768px) {
  .game-details-only-in-mobile {
    width: 100%;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}