.bag {
  width: 56px;
  height: 56px;
  background-color: $white;
  border-radius: 50px;
  border: 4px solid $light-gray;
  display: flex;
  box-shadow: 0 4px $dark-gray;
  transform: translateY(0) translateZ(0);
  transition: all 150ms linear;
  position: fixed;
  bottom: 20px;
  left: 17px;
  z-index: 1;
}

.bag:hover,
.bag:active,
.bag:focus {
  box-shadow: none;
  transform: translateY(4px) translateZ(0);
}

.bag-icon {
  background: url("../images/bag.svg") no-repeat;
  background-size: contain;
  width: 25px;
  height: 34px;
  display: block;
  margin: auto;
}

.bag:hover .bag-icon,
.bag:active .bag-icon,
.bag:focus .bag-icon {
  background: url("../images/bag-opened.svg") no-repeat;
}
