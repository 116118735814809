.eye-icon {
  width: 13px;
  height: 13px;
  display: block;
  border-radius: 75% 15%;
  transform: rotate(45deg);
  border: 2px solid $purple;
}

.eye-icon::before,
.eye-icon::after {
  content: "";
  border-radius: 50%;
  display: block;
  position: absolute;
}

.eye-icon::before {
  background: $purple;
  width: 10px;
  height: 10px;
  top: 0;
  left: 1px;
  z-index: 1;
}

.eye-icon::after {
  background: $white;
  width: 4px;
  height: 4px;
  top: 4px;
  left: 2px;
  z-index: 2;
}
