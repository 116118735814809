.modal-content.honey-flower {
  background: url("../images/honey-flower-background.png");
  background-color: $honey-flower;
  color: $white;
}

.modal-content.honey-flower h2 {
  color: $caramel;
}

.modal-content.honey-flower hr {
  border-style: dashed;
}

.modal-content.honey-flower .white-titles h2,
.modal-content.honey-flower .white-titles h3 {
  color: $white;
}

.modal-content.honey-flower .white-titles h3 {
  font-weight: 300;
}

.modal-content.honey-flower .modal-link {
  color: $white;
}

.modal-content.honey-flower .modal-link a {
  color: $tickle-me-pink;
}

.modal-content.honey-flower .modal-close-button {
  background-color: $white;
  color: $honey-flower ;
}
