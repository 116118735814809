.question-slide {
  position: relative;
}

.question-slide-arrow-prev,
.question-slide-arrow-next {
  position: absolute;
  top: 140px;
  width: 35px;
  height: 240px;
  cursor: pointer;
  z-index: 2;
}

.question-slide-arrow-prev {
  left: 0;
  border-radius: 0 20px 20px 0;
  background-color: $sky-blue;
}

.question-slide-arrow-next {
  right: 0;
  border-radius: 20px 0 0 20px;
  background-color: $tickle-me-pink;
}

.question-slide-arrow-prev::before,
.question-slide-arrow-next::before {
  position: absolute;
  left: 13px;
  top: 110px;
  border-color: $white;
}

@media (max-width: 768px) {
  .question-slide-arrow-prev,
  .question-slide-arrow-next {
    width: 10px;
  }

  .question-slide-arrow-prev::before,
  .question-slide-arrow-next::before {
    display: none;
  }
}
