.daily-mission-title {
  color: $tickle-me-pink;
  font-family: $montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
  text-align: center;
}

.daily-mission-days {
  color: $tickle-me-pink;
  text-align: center;
}

.performance-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 40px;
}

.performance-summary p {
  margin-top: 0;
}

.percentage-bars-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-wrap: wrap;
  margin: auto;
}

.performance-chart {
  width: 50%;
  margin-top: 30px;
}

.performance-chart .knowledge-area-name {
  color: $purple;
  font-size: 14px;
}

.performance-chart .answered {
  color: $dark-gray;
  line-height: 30px;
}

.performance-chart .correct,
.performance-chart .incorrect {
  color: $purple;
  line-height: 20px;
}

.performance-chart .incorrect {
  float: right;
}

@media (max-width: 768px) {
  .daily-mission-title {
    text-align: left;
  }

  .performance-chart,
  .percentage-bars-container,
  .performance-summary p,
  .performance-summary {
    width: 100%;
  }
}
