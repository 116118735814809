strong {
  font-weight: 700;
}

h2, h3 {
  font-family: $khand;
  line-height: 1.2em;
  color: $purple;
  margin-bottom: 20px;
}

h2 {
  font-size: 31px;
  font-weight: 700;
}

h3 {
  font-size: 19px;
  font-weight: 500;
}

h3.light {
  font-weight: 400;
}

h3.big {
  font-size: 25px;
}

h2.light {
  font-weight: 500;
}

h2.big {
  font-size: 39px;
}

h2.dotted {
  font-weight: 300;
  display: inline-block;
  padding: 0 40px 15px;
  border-bottom: 3px dotted $white;
}

h2.dotted strong {
  font-weight: 500;
}

p {
  font-size: 16px;
  line-height: 1.4em;
}

p.medium {
  font-size: 14px;
}

p.small {
  font-size: 12px;
}

p ~ p {
  margin-top: 20px;
}

.mute {
  color: $light-gray;
  font-size: 12px;
}

a {
  color: $main-color;
  font-weight: 500;
  text-decoration: underline;
}

a:hover,
a:active {
  color: $purple;
}

.pink {
  color: $tickle-me-pink;
}

.dark-gray {
  color: $dark-gray;
}

.purple {
  color: $purple;
}

.green {
  color: $green;
}

.caramel {
  color: $caramel !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.honey-flower {
  color: $honey-flower;
}
