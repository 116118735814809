.exam-rules-container {
  padding: 60px 0;
  width: 85%;
  margin: 0 auto;
}

.exam-rules-container h2 {
  text-align: center;
}

.exam-rules-container a {
  display: block;
  margin: 0 auto;
}

.exam-rules-container hr {
  margin: 60px 0 20px
}

@media (max-width: 768px) {
  .exam-rules-container {
    padding-top: 90px;
  }
}
