.gamification-rounded {
  border-radius: 41px;
  background-color: $honey-flower;
  margin: 20px auto 0;
  display: inline-block;
  padding: 10px 30px;
}

.daily-mission-performance {
  color: $white;
  font-size: 14px;
}

.earnings-container {
  background: url("../images/stars.svg") no-repeat center top;
  position: relative;
  margin-top: 15px;
  height: 445px;
}

.earnings-container::before {
  content: "";
  background: url("../images/glow.svg") no-repeat center top;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -175px;
  width: 350px;
  height: 350px;
  display: block;
  z-index: -1;
}

.earnings-brain {
  background: url("../images/icon-xp.svg") no-repeat;
  background-size: 100%;
  width: 180px;
  height: 164px;
  display: block;
  margin: 40px auto 0;
}

.earnings-container .avatar {
  height: 200px;
}

.earnings-container .ruby-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.earnings-container .ruby-container .gamification-rounded {
  margin: 0;
}

.earnings-container .xp-points,
.earnings-container .ruby-points {
  color: $tickle-me-pink;
  font-family: $khand;
  line-height: 1em;
}

.earnings-container .xp-points {
  font-size: 50px;
  line-height: 1em;
}

.earnings-container .ruby-points {
  font-size: 22px;
}

.earnings-container .icon-ruby {
  margin-left: -15px;
}
