.white.btn-light {
  border-color: $white;
  color: $white;
}

.white.btn-light:hover,
.white.btn-light:active,
.white.btn-light:focus {
  border-color: $white !important;
  color: $white !important;
}

.white.btn-light .arrow-icon {
  position: absolute;
  top: 4px;
  right: 9px;
  border: $white 1px solid;
  background-color: transparent;
  width: 20px;
  height: 20px;
}

.white.btn-light .arrow-icon::before {
  border-color: $white;
}

.white.btn-light .arrow-icon-down::before {
  padding: 3px;
}

.white.btn-light .arrow-icon-right::before {
  padding: 3px;
  transform: rotate(315deg) translateX(-2px) translateY(1px);
}

@media (max-width: 768px) {
  .white.btn-light {
    font-size: 10px;
  }
}
