.question-alternative {
  padding: 0 5%;
  position: relative;
}

.question-alternative hr {
  width: 100%;
}

.question-alternative .form-group {
  margin-bottom: 0;
}

.question-alternative .form-label {
  padding-top: 40px;
  padding-bottom: 40px;
}

.question-alternative .form-label,
.question-alternative .form-label p {
  font-size: 16px;
  line-height: 28px;
  color: $black;
}

.question-alternative .form-label::before {
  top: 40px;
  border-color: $dark-gray;
}

.question-alternative .form-label::after {
  top: 44px;
}

.question-alternative .form-radio:checked ~ .form-label::after {
  background-color: $orange;
}

/* correct or wrong */
.question-alternative.correct .form-label,
.question-alternative.correct .form-label *,
.question-alternative.wrong .form-label,
.question-alternative.wrong .form-label * {
  color: $white;
}

.question-alternative.correct .form-label::before,
.question-alternative.wrong .form-label::before {
  background-color: $white;
  border-color: $white;
}

/* correct */
.question-alternative.correct {
  background-color: $dark-green;
}

.question-alternative.correct .form-radio ~ .form-label::after {
  background-color: $white;
  width: 6px;
  height: 12px;
  border-radius: 1px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: $dark-green;
  transform: rotateZ(37deg);
  z-index: 1;
  left: 9px;
}

.question-alternative.correct hr {
  border-color: $dark-green;
}

/* wrong */
.question-alternative.wrong {
  background-color: $red;
}

.question-alternative.wrong .form-radio ~ .form-label::after {
  content: "×";
  color: $red;
  font-size: 30px;
  background-color: transparent;
  top: 40px;
  left: 6px;
  z-index: 1;
  border-radius: 0;
}

.question-alternative.wrong hr {
  border-color: $red;
}

/* correct-answer */
.question-alternative .correct-answer {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4.8%;
  background-color: $dark-green;
  color: $white;
  border-radius: 20px;
  padding: 7px 12px;
}

.question-alternative .correct-answer::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 10px;
  border-radius: 1px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: $white;
  transform: rotateZ(37deg);
  margin-right: 10px;
}

.question-alternative .correct-answer::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-color: $dark-green transparent transparent;
  border-width: 7px 7px 0;
  position: absolute;
  bottom: -7px;
  left: 12px;
}
