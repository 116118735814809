.form-label {
  font-size: 16px;
  line-height: 1em;
  color: $dark-gray;
  user-select: none;
}

.form-label a {
  color: $dark-gray;
}

.form-field ~ .form-label {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: 0.2s ease all;
}

.form-field[readonly] ~ .form-label,
.form-field:focus ~ .form-label,
.form-field:valid ~ .form-label,
.form-group.error .form-field ~ .form-label {
  top: -35px;
}
