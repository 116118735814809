.modal.storytelling {
  padding-top: 80px;
}

.storytelling-content {
  margin: auto;
  width: 95%;
  max-width: 450px;
}

.storytelling-content .modal-content {
  border: 7px solid $sky-blue;
  margin-bottom: 30px;
}

.storytelling-content .modal-content .avatar-circle {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border: 5px solid $white;
  border-radius: 50%;
  margin: -110px auto 20px;
  background-color: $white;
  position: relative;
}

.storytelling-content .modal-content .avatar-circle::before {
  content: "";
  width: 120px;
  height: 120px;
  border: 5px solid $sky-blue;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
}

.storytelling-content .btn {
  max-width: 230px;
  display: block;
  margin: 0 auto;
}

.storytelling-bullets {
  margin-bottom: 20px;
  text-align: center;
}

.storytelling-bullets ul li {
  list-style: none;
  display: inline-block;
  background: $white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 10px;
  overflow: hidden;
}

.storytelling-bullets ul li.active {
  background-color: $sky-blue;
}
