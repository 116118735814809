.avatar-slide {
  margin: -120px auto 40px;
  position: relative;
  width: 70%;
}

.avatar-slide .slick-slide {
  filter: grayscale(100%);
  text-align: center;
  outline: none;
  cursor: pointer;
}

.avatar-slide .slick-slide.slick-center {
  filter: none;
}

.avatar-slide .slick-slide img {
  display: inline;
}

.avatar-slide-name {
  margin-top: 30px;
}

.avatar-slide-bio {
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
  line-height: 2em;
}

.avatar-slide-arrow-prev,
.avatar-slide-arrow-next {
  position: absolute;
  top: 60px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  color: $purple;
  cursor: pointer;
  z-index: 10;
}

.avatar-slide-arrow-prev:focus,
.avatar-slide-arrow-next:focus {
  outline: none;
}

.avatar-slide-arrow-prev::before,
.avatar-slide-arrow-next::before {
  padding: 12px;
}

.avatar-slide-arrow-prev {
  left: -40px;
}

.avatar-slide-arrow-prev::before {
  transform: rotate(135deg) translateX(-10px) translateY(-20px);
}

.avatar-slide-arrow-next {
  right: -30px;
}

.avatar-slide-arrow-next::before {
  transform: rotate(315deg) translateX(-10px) translateY(0);
}
