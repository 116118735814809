.trail-avatar {
  position: absolute;
  width: 120px;
  height: 161px;
  z-index: 3;
  transition: all 1500ms ease;
  animation: fadeIn 500ms ease-in-out, floating 2s infinite alternate;
}

.trail-avatar.inactive {
  width: 120px;
  height: 103px;
}

.trail-avatar-0 {
  top: -110px;
  left: 270px;
}

.trail-avatar-0.inactive {
  top: -110px;
  left: 270px;
}

.trail-avatar-1 {
  top: 10px;
  left: 100px;
}

.trail-avatar-1.inactive {
  top: 10px;
  left: 100px;
}

.trail-avatar-2 {
  top: 165px;
  left: 20px;
}

.trail-avatar-2.inactive {
  top: 165px;
  left: 20px;
}

.trail-avatar-3 {
  top: 255px;
  left: 230px;
}

.trail-avatar-3.inactive {
  top: 255px;
  left: 230px;
}

.trail-avatar-4 {
  top: 380px;
  left: 13px;
}

.trail-avatar-4.inactive {
  top: 380px;
  left: 13px;
}

.trail-avatar-5 {
  top: 490px;
  left: 275px;
}

.trail-avatar-5.inactive {
  top: 480px;
  left: 275px;
}

.trail-avatar-6 {
  top: 550px;
  left: 13px;
}

.trail-avatar-6.inactive {
  top: 540px;
  left: 18px;
}
