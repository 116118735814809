.form-checkbox {
  position: absolute;
  left: -9999999px;
}

.form-checkbox ~ .form-label {
  font-size: 12px;
  padding-left: 25px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
}

.form-checkbox ~ .form-label::before,
.form-checkbox ~ .form-label::after {
  content: " ";
  transition: 0.2s ease all;
  position: absolute;
  top: 0;
  left: 0;
}

.form-checkbox ~ .form-label::before {
  top: 2px;
  left: 1px;
  transform-origin: 100% 100%;
  width: 6px;
  height: 12px;
  border-radius: 1px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  border-color: transparent;
  transform: rotateZ(37deg);
}

.form-checkbox:checked ~ .form-label::before {
  border-color: $white;
  z-index: 1;
}

.form-checkbox ~ .form-label::after {
  width: 16px;
  height: 16px;
  border: 2px solid $dark-gray;
  border-radius: 2px;
}

.form-checkbox:checked ~ .form-label::after {
  background-color: $purple;
  border-color: $purple;
}
