.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buttons-container .btn,
.buttons-container .btn ~ .btn {
  margin: 10px;
}
