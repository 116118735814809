.list-box {
  border: 2px solid $tickle-me-pink;
  margin: 20px auto;
  width: 70%;
}

.list-box ul {
  padding: 20px;
}

.list-box ul li {
  list-style: none;
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}

.list-box ul li:last-child {
  margin-bottom: 0;
}

.list-box-message {
  color: $white;
  background-color: $ripe-plum;
  font-size: 13px;
  padding: 18px;
  text-align: center;
  line-height: 1.4em;
}

@media (max-width: 767px) {
  .list-box {
    width: 100%;
  }
}
