.exam-answer-title {
  margin-top: 70px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.exam-answer-title h2 {
  font-size: 25px;
}

.exam-question {
  padding: 0 85px;
  margin: 20px 0;
}

.exam-question .question-separator {
  margin-top: 30px;
}

.exam-question .question-alternative {
  padding: 0;
}

.exam-question .buttons-container {
  margin-top: 30px;
}

.exam-rules {
  text-align: left;
}

.exam-pause {
  width: 550px;
  max-width: 100%;
}

.exam-pause .pause-icon {
  border: 3px solid $purple;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.exam-pause .pause-icon::before,
.exam-pause .pause-icon::after {
  width: 4px;
  height: 30px;
  margin: 0 3px;
  border-width: 3px;
}

.exam-pause-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.exam-pause-dismiss {
  color: $orange;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.2em;
}

.answer-key-stats {
  display: inline-block;
  font-family: $khand;
  font-size: 16px;
}

.answer-key-stats .check-icon,
.answer-key-stats .error-icon {
  width: 16px;
  height: 16px;
  margin: 0 5px;
}

.answer-key-stats .error-icon {
  background-color: $white;
  border: 2px solid $red;
  color: $red;
  line-height: 16px;
}

.answer-key-stats .check-icon {
  background-color: $white;
  border-color: $dark-green;
}

.answer-key-stats .check-icon::before,
.answer-key-stats .check-icon::after {
  border-color: $dark-green;
  margin: 1px 2px 0;
}

@media (max-width: 768px) {
  .exam-question {
    padding: 0 20px;
  }
}
