@import "icon/eye.css";

.arrow-icon,
.error-icon,
.pause-icon,
.check-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.arrow-icon {
  background-color: $white;
}

.arrow-icon-down {
  width: 18px;
  height: 18px;
  display: inline-block;
}

.arrow-icon-left::before,
.arrow-icon-right::before,
.arrow-icon-down::before {
  content: "";
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
}

.arrow-icon-left::before {
  transform: rotate(135deg) translateX(-1px) translateY(-2px);
}

.arrow-icon-right::before {
  transform: rotate(315deg) translateX(-3px) translateY(0px) scale(.6);
}

.arrow-icon-down::before {
  transform: rotate(45deg);
}

.error-icon {
  background-color: $red;
  font-family: sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  color: $white;
}

.checkbox-icon {
  margin-right: 12px;
  border: 2px solid $light-gray;
  background-color: transparent;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 10%;
  width: 15px;
  height: 15px;
}

.checkbox-icon::after,
.checkbox-icon::before {
  background: $white;
  content: "";
  position: absolute;
  border-style: solid;
  border-color: $white;
  border-width: 3px 3px 3px 0;
}

.checkbox-icon::after {
  height: 5px;
  left: 4px;
  top: 2px;
  transform: rotate(155deg);
}

.checkbox-icon::before {
  height: 13px;
  left: 12px;
  top: -6px;
  transform: rotate(43deg);
}

.chart-icon {
  background-color: transparent;
  border: 2px solid $white;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 27px;
  width: 2px;
  height: 9px;
}

.chart-icon::after,
.chart-icon::before {
  bottom: -2px;
  width: 2px;
  border: 2px solid $white;
  content: "";
  position: absolute;
}

.chart-icon::after {
  height: 7px;
  left: 5px;
}

.chart-icon::before {
  height: 15px;
  left: 12px;
  margin-right: 12px;
}

/* Pause White Icon */

.pause-icon-white {
  margin: 12px;
  border: 2px solid $white;
  background-color: transparent;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.pause-icon-white.small {
  margin: 0 12px 0 0;
  width: 16px;
  height: 16px;
}

.pause-icon-white::after,
.pause-icon-white::before {
  background: $white;
  content: "";
  position: absolute;
  border-style: solid;
  border-color: $white;
  border-width: 2px 2px 2px 0;
}

.pause-icon-white.small::after {
  height: 4px;
  left: 5px;
  top: 4px;
}

.pause-icon-white.small::before {
  height: 4px;
  left: 9px;
  top: 4px;
}

/* Star Icon */

.star-icon {
  float: left;
  margin: 3px 12px 3px 0;
  position: relative;
  display: block;
  width: 0;
  height: 0;
  border-right: 11px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 10px solid transparent;
  transform: rotate(35deg);
}

.star-icon::before {
  border-bottom: 7px solid #fff;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -5px;
  left: -6px;
  display: block;
  content: "";
  transform: rotate(-35deg);
}

.star-icon::after {
  position: absolute;
  display: block;
  top: 0;
  left: -10px;
  width: 0;
  height: 0;
  border-right: 11px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 10px solid transparent;
  transform: rotate(-70deg);
  content: "";
}

/* Clock Icon */
.clock-icon {
  margin: 12px;
  background-color: $purple;
  border: 2px solid $purple;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.clock-icon.white-transparent {
  border: 2px solid $white;
  background-color: transparent;
}

.clock-icon.small {
  margin: 0 12px 0 0;
  width: 15px;
  height: 15px;
}

.clock-icon::after,
.clock-icon::before {
  background: $white;
  content: "";
  position: absolute;
  border-style: solid;
  border-color: $white;
  border-width: 2px 2px 2px 0;
}

.clock-icon::after {
  height: 9px;
  left: 14px;
  top: 3px;
}

.clock-icon.small::after {
  height: 5px;
  left: 7px;
  top: 2px;
}

.clock-icon::before {
  height: 4px;
  left: 17px;
  top: 8px;
  transform: rotate(45deg);
}

.clock-icon.small::before {
  height: 2px;
  left: 9px;
  top: 5px;
}

.clock-icon.not-pause {
  margin: 0 12px 0 0;
  width: 15px;
  height: 15px;
}

.clock-icon.not-pause::after,
.clock-icon.not-pause::before {
  height: 14px;
  left: 7px;
  top: -2px;
}

.clock-icon.not-pause::after {
  transform: rotate(135deg);
}

.clock-icon.not-pause::before {
  transform: rotate(45deg);
}

.clock-icon.pause {
  margin: 0 12px 0 0;
  width: 15px;
  height: 15px;
}

.clock-icon.pause::after,
.clock-icon.pause::before {
  height: 4px;
  top: 3px;
  transform: rotate(180deg);
}

.clock-icon.pause::after {
  left: 8px;
}

.clock-icon.pause::before {
  left: 5px;
}

.padlock-icon {
  background: url("../images/padlock.svg") no-repeat;
  display: block;
  width: 22px;
  height: 26px;
}

.flag-icon {
  background: url("../images/flag.svg") no-repeat;
  display: block;
  width: 22px;
  height: 26px;
}

.icon-ruby {
  background: url("../images/icon-ruby.svg") no-repeat;
  background-size: 100%;
  display: block;
  width: 70px;
  height: 45px;
}

.pause-icon {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pause-icon::before,
.pause-icon::after {
  content: "";
  border: 1px solid $purple;
  margin: 0 2px;
  height: 10px;
  display: inline-block;
}

.check-icon {
  background-color: $purple;
  width: 28px;
  height: 28px;
  border: 2px solid $white;
}

.check-icon.white-transparent {
  border: 2px solid $white;
  background-color: transparent;
  height: 14px;
  width: 14px;
  margin-right: 10px;
}

.check-icon.white-transparent::before,
.check-icon.white-transparent::after {
  content: "";
  border: 1px solid $white;
  margin: 1px 2px 0;
  display: inline-block;
}

.check-icon.white-transparent::before {
  transform: rotateZ(135deg);
  height: 5px;
}

.check-icon.white-transparent::after {
  transform: rotateZ(45deg);
  height: 8px;
}

.check-icon::before,
.check-icon::after {
  content: "";
  border: 1px solid $white;
  margin: 7px 2px 0;
  display: inline-block;
}

.check-icon::before {
  transform: rotateZ(135deg);
  height: 5px;
}

.check-icon::after {
  transform: rotateZ(45deg);
  height: 10px;
}

.essay-icon {
  background: url("../images/icon-essay.svg") no-repeat;
  width: 14px;
  height: 18px;
  display: inline-block;
}

.evaluated-icon {
  background: url("../images/icon-evaluated.svg") no-repeat;
  width: 14px;
  height: 18px;
  display: inline-block;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.hourglass-icon {
  display: flex;
  flex-direction: column;
  width: 8px;
  height: 14px;
  padding: 0 2px;
  border-width: 1px 0;
  border-style: solid;
  border-color: $white;
  animation: rotate 1s infinite;
}

.hourglass-icon::before,
.hourglass-icon::after {
  content: "";
  display: inline-block;
  border-style: solid;
  border-color: $white;
  width: 6px;
  height: 6px;
}

.hourglass-icon::before {
  border-width: 0 1px 1px;
  border-radius: 0 0 10px 10px;
}

.hourglass-icon::after {
  border-width: 1px 1px 0;
  border-radius: 10px 10px 0 0;
}

.print-icon {
  color: $black;
  width: 15px;
  height: 6px;
  border: solid 1px;
}

.print-icon::before,
.print-icon::after {
  content: "";
  position: absolute;
  background-color: $white;
}

.print-icon::before {
  left: 2px;
  top: -3px;
  height: 13px;
  width: 9px;
  border: solid 1px;
}

.print-icon::after {
  left: 1px;
  top: -1px;
  width: 13px;
  height: 3px;
  border-top: solid 1px;
  border-bottom: solid 1px;
}

.picture-icon {
  color: $black;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: solid 1px;
}

.picture-icon::before,
.picture-icon::after {
  content: "";
  position: absolute;
}

.picture-icon::before {
  left: 2px;
  top: 2px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  border: solid 1px;
}

.picture-icon::after {
  left: 2px;
  top: 9px;
  width: 12px;
  height: 8px;
  border-top: solid 1px;
  border-right: solid 1px;
  transform: rotate(-45deg);
}

.socio-stats-container {
  padding-left: 25%;
  margin-bottom: 15px;
}

.arrow-icon.small {
  margin: 0 12px 0 0;
  width: 16px;
  height: 16px;
}

.arrow-icon-right.to-do {
  border: 2px solid $white;
  color: $purple;
}

.arrow-icon-right:before {
  transform: rotate(315deg) translateX(-3px) translateY(0) scale(.6);
}
