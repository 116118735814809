.exam-slide-arrow-prev,
.exam-slide-arrow-next {
  position: absolute;
  top: 100px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  z-index: 10;
  background-color: $purple;
  color: $white;
}

.exam-slide-arrow-prev.slick-disabled,
.exam-slide-arrow-next.slick-disabled {
  display: none;
}

.exam-slide-arrow-prev:focus,
.exam-slide-arrow-next:focus {
  outline: none;
}

.exam-slide-arrow-prev::before,
.exam-slide-arrow-next::before {
  padding: 6px;
}

.exam-slide-arrow-prev::before {
  transform: rotate(135deg) translateX(10px) translateY(-14px);
}

.exam-slide-arrow-next::before {
  transform: rotate(315deg) translateX(-14px) translateY(10px);
}

.exam-slide-arrow-prev {
  left: -20px;
}

.exam-slide-arrow-next {
  right: -20px;
}
