.language-chooser-container {
  padding-top: 85px;
  width: 80%;
  margin: 0 auto;
}

.language-chooser-container h2 {
  text-align: center;
}

.language-chooser-container p {
  line-height: 1.8em;
}

.language-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
}

.language-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 47%;
  max-width: 150px;
}

.language-option .form-group {
  margin-top: 20px;
}

.language-option .form-label {
  line-height: 28px;
  font-size: 16px;
  font-weight: 700;
  color: $black;
}
