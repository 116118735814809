.exam-card {
  width: 400px;
  max-width: 100%;
  border-radius: 20px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  color: $white;
}

.exam-card-title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  border-bottom: 2px dotted $white;
}

.exam-stats-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
}

.socio-stats-container {
  padding-left: 25%;
  margin-bottom: 15px;
}

.expiration-start-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.expiration-start-container.expired {
  justify-content: center;
}

.expiration-start-container.expired .btn-light {
  width: auto;
}

.exam-card-metadata {
  display: flex;
}

.exam-card-metadata-subitem {
  font-size: 10px;
  margin-top: 3px;
  font-style: italic;
  opacity: 0.6;
}

.exam-card .progress-bar-text {
  font-family: $khand;
  font-size: 13px;
  padding-left: 10px;
  margin-top: 5px;
}

.not-started-exam-card-info {
  padding-left: 25%;
  margin-bottom: 15px;
}

.not-started-exam-card-release-date {
  width: 80%;
  margin: 20px auto 0;
  border-top: 2px solid $moon-raker;
  padding-top: 20px;
  font-size: 14px;
  text-align: center;
}

.essay-theme {
  display: flex;
  padding: 0 20px;
  align-items: flex-start;
  box-sizing: border-box;
  font-size: 13px;
}

.essay-theme-prefix {
  display: flex;
  align-items: center;
}

.essay-theme-prefix .essay-icon {
  flex-shrink: 0;
  margin-right: 5px;
}

.essay-theme-title {
  margin-left: 5px;
  padding-top: 3px;
  text-transform: uppercase;
  line-height: 1.2em;
  font-weight: 500;
  color: $moon-raker;
}

.essay-evaluated {
  text-transform: uppercase;
  font-style: italic;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.essay-evaluated .evaluated-icon {
  margin-right: 5px;
}

.exam-card-aggregated-grade {
  text-align: center;
  font-family: $khand;
  font-size: 24px;
  margin: 40px 0 20px;
}

.exam-card-finalized {
  text-align: center;
  margin-bottom: 15px;
  font-size: 11px;
  font-style: italic;
}

.exam-card-link {
  color: $white;
  text-align: center;
  text-decoration: none;
  display: block;
}

.exam-card-link:hover {
  color: $white;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .exam-card {
    max-width: 98%;
  }
}
