.download-essay-template-container {
  width: 220px;
  margin: 0 auto;
  line-height: 20px;
  padding-top: 20px;
}

.download-essay-template-container a,
.download-essay-template-container i {
  color: $light-gray;
}

.download-essay-template-container a {
  margin-left: 25px;
}

.download-essay-template-container .print-icon {
  position: absolute;
  margin: 5px 0 0 2px;
}
