.satellite-container {
  padding-top: 85px;
}

.satellite-content-container {
  margin: 40px auto 20px;
  width: 95%;
}

.satellite-container h2 {
  text-align: center;
}

.satellite-content-title {
  margin-bottom: 20px;
}

.satellite-disclaimer {
  text-align: center;
}

/* exams */
.satellite-content {
  margin-bottom: 25px;
}

.satellite-content-exams .satellite-content-title {
  color: $blue;
}

.satellite-content-exams .exam-card {
  background-color: $blue;
}

/* essay */
.satellite-content-essays .satellite-content-title {
  color: $purple;
}

.satellite-content-essays .exam-card {
  background-color: $purple;
}

/* socio_emotionals */
.satellite-content {
  margin-bottom: 25px;
}

.satellite-content-socio-emotionals .satellite-content-title {
  color: $blue;
}

.satellite-content-socio-emotionals .exam-card {
  background-color: $blue;
}
