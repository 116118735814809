.exam-finalized-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 660px;
  max-width: 90%;
}

.exam-finalized-content img {
  flex-shrink: 1;
}

.exam-finalized-content p {
  width: 480px;
  margin-left: 20px;
  color: $white;
  line-height: 2em;
}

.exam-finalized-link {
  margin-top: 30px;
  color: $orange;
  font-size: 14px;
}

.exam-finalized-link:active,
.exam-finalized-link:focus,
.exam-finalized-link:hover {
  color: $white;
}

@media (max-width: 768px) {
  .exam-finalized-content p {
    font-size: 14px;
    line-height: 1.4em;
  }
}
