.essay-upload-container {
  padding: 70px 0 20px;
  width: 85%;
  margin: 0 auto;
}

.essay-upload-container hr {
  margin: 40px 0 20px;
}

.essay-upload .modal-link{
  display: inline;
  color: $tickle-me-pink;
}

.essay-upload-instructions .instructions-items-container {
  margin-top: 20px;
}

.essay-upload-instructions .instructions-hr {
  border-style: dashed;
  margin: 0;
  border-color: $link-water-blue;
}

.essay-upload-instructions h3 {
  text-align: center;
  margin-bottom: 10px;
}

.essay-upload-instructions p {
  line-height: 20px;
}

.essay-upload-instructions .print-icon {
  position: absolute;
  margin: 5px 0 0 2px;
}

.essay-upload-instructions .btn-file-upload {
  display: block;
  margin: 0 auto;
}

.essay-upload-confirmation {
  text-align: center;
}

.essay-upload-confirmation a,
.essay-upload-instructions a {
  margin-top: 20px;
}

.essay-upload-confirmation a {
  display: block;
  margin: 0 auto;
}

.essay-upload-confirmation .image-preview {
  border: solid $purple;
  margin-top: 30px;
  max-width: 90%;
  padding: 5px;
}

.essay-upload-confirmation .image-link-container {
  width: 120px;
  padding-top: 20px;
  margin: 0 auto;
  line-height: 20px;
  text-align: left;
}

.essay-upload-confirmation .image-link-container a,
.essay-upload-confirmation .image-link-container i {
  color: $light-gray;
}

.essay-upload-confirmation .image-link-container a {
  margin-left: 25px;
}

.essay-upload-confirmation .picture-icon {
  position: absolute;
  margin-left: 2px;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .essay-upload-instructions .btn-file-upload span {
    font-size: 14px;
  }
}
