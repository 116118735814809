.btn-link {
  border: 2px solid transparent;
  color: $purple;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  color: $btn-primary-color;
}
