.btn-primary.blue {
  background-color: $btn-primary-blue;
  color: $white;
  box-shadow: 2px 4px $btn-primary-blue-shadow;
}

.btn-primary.blue:hover,
.btn-primary.blue:active,
.btn-primary.blue:focus {
  background-color: $btn-primary-blue-shadow;
  color: $white;
}

.btn-primary.blue .arrow-icon::before {
  border-color: $btn-primary-blue;
}

.btn-primary.blue:hover .arrow-icon::before,
.btn-primary.blue:active .arrow-icon::before,
.btn-primary.blue:focus .arrow-icon::before {
  border-color: $btn-primary-blue-shadow;
}
