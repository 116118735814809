* {
  margin: 0;
  padding: 0;
}

html,
body,
#app,
.container {
  height: 100%;
}

body {
  font: 400 12px/12px $montserrat;
  color: $main-color;
}

hr {
  border-color: $light-gray;
  border-style: solid;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes floating {
  from {
    transform: translateY(-5%);
  }

  to {
    transform: translateY(0);
  }
}
