.payment-methods {
  margin-top: 30px;
}

.form-group-payment {
  margin: 30px 0;
}

.line-code-container {
  border: 2px solid $tickle-me-pink;
  padding: 20px;
}

.form .payment-method-label {
  margin-bottom: 20px;
}
