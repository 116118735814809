.btn-file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}

.btn-input-file-upload {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  height: 60px;
}
