.modal-content.purple {
  background-color: $purple;
}

.modal-content.purple h2 {
  color: $white;
}

.modal-content.purple p {
  color: $white;
}

.modal-content.purple .modal-link {
  color: $white;
}

.modal-content.purple .modal-link a {
  color: $tickle-me-pink;
}
