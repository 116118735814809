.btn-primary {
  background-color: $btn-primary-color;
  border: none;
  color: $white;
  box-shadow: 2px 4px $btn-primary-shadow;
  transform: translateY(0) translateZ(0);
  transition: all 150ms linear;
  margin-bottom: 4px;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: $btn-primary-shadow;
  box-shadow: none;
  transform: translateY(4px) translateZ(2px);
  color: $white;
}

.btn-primary .btn-icon,
.btn-primary .btn-loader {
  position: absolute;
  top: 15px;
  right: 15px;
  transition: all 150ms linear;
}

.btn-primary .arrow-icon::before {
  border-color: $btn-primary-color;
}

.btn-primary .check-icon {
  background-color: $btn-primary-color;
}

.btn-primary:hover .arrow-icon::before,
.btn-primary:active .arrow-icon::before,
.btn-primary:focus .arrow-icon::before {
  border-color: $btn-primary-shadow;
}

.btn-primary:hover .check-icon,
.btn-primary:active .check-icon,
.btn-primary:focus .check-icon {
  background-color: $btn-primary-shadow;
}

.btn-primary.waiting {
  color: $btn-primary-disabled-text;
}
