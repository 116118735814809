.questions-selector {
  padding: 0 60px;
  margin-bottom: 20px;
  position: relative;
}

.question-selector {
  margin: 0 auto;
  display: block;
  width: 44px;
  height: 44px;
  font-family: $khand;
  font-weight: 500;
  font-size: 20px;
  color: $purple;
  border: 2px solid $purple;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}

.question-selector-container {
  position: relative;
}

.question-selector-icon.check-icon {
  background-color: $dark-green;
  border: 0;
}

.question-selector-icon.error-icon {
  line-height: 24px;
}

.question-selector-icon.check-icon,
.question-selector-icon.error-icon {
  position: absolute;
  top: 0;
  right: 20px;
  width: 24px;
  height: 24px;
}

.question-selector:hover,
.question-selector:active,
.question-selector:focus,
.question-selector.active {
  background-color: $purple;
  color: $white;
}

.question-selector.inactive {
  background-color: $purple;
  color: $white;
  opacity: 0.2;
}

.questions-selector-arrow-prev,
.questions-selector-arrow-next {
  background-color: $orange;
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 1;
}

.questions-selector .slick-disabled {
  cursor: default;
  opacity: 0.2;
}

.questions-selector-arrow-prev {
  left: -40px;
}

.questions-selector-arrow-next {
  right: -40px;
}

.questions-selector-arrow-prev::before,
.questions-selector-arrow-next::before {
  color: $white;
}

.questions-selector-arrow-next::before {
  transform: rotate(315deg) translateX(-2px) translateY(1px);
}
