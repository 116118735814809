.subscription-offer-price {
  background-color: $caramel;
  border-radius: 4px;
  padding: 10px 0;
  margin: 50px auto 0;
  position: relative;
  width: 200px;
  font-family: $khand;
  font-weight: 500;
}

.subscription-offer-price::after {
  content: "";
  display: block;
  background: url("../images/avatar/nuno.svg") no-repeat;
  background-size: contain;
  width: 120px;
  height: 146px;
  position: absolute;
  right: -65px;
  top: -40px;
}

.subscription-offer-currency,
.subscription-offer-value {
  color: $purple;
}

.subscription-offer-currency {
  font-size: 18px;
  line-height: 1em;
  margin-right: 5px;
}

.subscription-offer-value {
  font-size: 38px;
  line-height: 1em;
}

.subscription-offer-installments {
  color: $main-color;
  margin: 5px 0 0;
}

.subscription-offer-exclusive {
  font-size: 13px;
}
