.btn-primary.white {
  background-color: $white;
  color: $purple;
  box-shadow: 2px 4px $gray;
}

.btn-primary.white:hover,
.btn-primary.white:active,
.btn-primary.white:focus {
  background-color: $gray;
  color: $purple;
}
