.satellite-menu {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
  border-bottom: 1px solid $gray;
}

.satellite-menu .menu-item {
  width: 33%;
  font-family: $khand;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: $purple;
  cursor: pointer;
  padding-bottom: 30px;
  border-bottom: 3px solid transparent;
  letter-spacing: 1px;
}

.satellite-menu .menu-item.selected {
  font-weight: 700;
  border-bottom-color: $purple;
}
