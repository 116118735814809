.form {
  width: 100%;
  padding-bottom: 10px;
}

.form h2 {
  text-align: center;
}

.form h2 + .form-group,
.form h3 + .form-group {
  margin-top: 50px;
}

.form .btn {
  display: block;
  margin: 0 auto;
}

fieldset {
  border: 0;
}

input {
  background-color: transparent;
}
