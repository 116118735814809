.essay-topic-container {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;
}

.essay-topic-container hr {
  margin: 20px 0;
}

.essay-topic-container img {
  max-width: 95%;
}

.essay-topic-container .instructions-hr {
  border-style: dashed;
  margin: 0;
  border-color: $link-water-blue;
}
