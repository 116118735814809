.fullscreen-modal-wrapper {
  overflow: auto;
  position: relative;
  min-height: 100%;
}

.modal.fullscreen {
  padding: 0;
}

.modal-content.fullscreen {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  overflow-x: hidden;
  overflow-y: auto;
}
