.form-group {
  margin-bottom: 60px;
  position: relative;
}

.form-group.grid {
  display: flex;
  justify-content: space-between;
}

.form-group.grid > .form-group {
  width: 47%;
  margin-bottom: 0;
}

.form-group .eye-icon {
  position: absolute;
  bottom: 10px;
  right: 0;
  cursor: pointer;
}

.form-group .eye-icon.text {
  border-color: $orange;
}

.form-group .eye-icon.text::before {
  background: $orange;
}
