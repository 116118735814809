.user-info-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.user-info-container p {
  margin-top: 5px;
}

.user-info-container .btn-primary {
  margin-top: 10px;
}

.user-info-container h2 {
  margin-bottom: 0;
}

.user-avatar-border {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border: 5px solid $purple;
  border-radius: 50%;
  margin-right: 20px;
}

.user-avatar-border .avatar {
  width: 120px;
  margin-left: 3px;
  margin-top: 5px;
}

.user-avatar-container {
  position: relative;
}

.icon-pencil {
  content: "";
  background: url("../images/icon-pencil.svg") no-repeat;
  background-size: 45px;
  width: 50px;
  height: 50px;
  bottom: -5px;
  right: 15px;
  position: absolute;
}

.bag-menu {
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin: 0 auto;
  line-height: 40px;
}

.bag-menu .menu-item {
  font-size: 24px;
  text-transform: uppercase;
  color: $purple;
  text-decoration: none;
  position: relative;
}

.bag-menu .menu-item.selected {
  font-weight: 700;
  border-bottom: 3px solid $purple;
}

.bag-content-container {
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .bag-menu {
    width: 100%;
  }

  .user-info-container p,
  .bag-menu .menu-item {
    font-size: 12px;
  }

  .user-info-container h2 {
    font-size: 16px;
  }

  .bag-content-container {
    margin-left: 10px;
  }

  .icon-pencil {
    background-size: 35px;
    bottom: -15px;
    right: 5px;
  }

  .user-info-container .avatar {
    height: 142px;
    margin-top: 10px;
  }

  .user-avatar-border {
    width: 90px;
    height: 90px;
  }
}
