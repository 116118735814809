.question-container {
  background-color: $white;
}

.question-container hr {
  width: 90%;
  margin: 0 auto;
}

.question-container .buttons-container {
  margin: 40px 0;
}
