.exam-card-subitem {
  margin-top: 30px;
}

.exam-card-subitem-top {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px dotted $white;
}

.exam-card-subitem-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  align-items: center;
}

.exam-card-subitem-top .arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.exam-card-subitem-top .arrow-icon::before {
  border-color: $blue;
  padding: 3px;
  border-width: 0 1px 1px 0;
  transform: rotate(315deg) translateX(-1px) translateY(1px);
}

.exam-card-subitem-title {
  line-height: 1.2em;
}

.exam-card-subitem-grade {
  font-family: $khand;
  font-size: 18px;
  min-width: 90px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.exam-card-subitem-grade i,
.exam-card-subitem-grade strong {
  margin-left: 10px;
}

.exam-card-subitem-grade strong {
  font-size: 24px;
}

.exam-card-subitem-button {
  font-family: $khand;
  text-decoration: none;
  color: $white;
}

.exam-card-subitem-button:hover {
  color: $white;
}
