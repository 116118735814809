.form-group.error .form-label {
  color: $red;
}

.form-group.error .form-field {
  border-color: $red;
  color: $red;
}

.form-group.error input.form-field {
  -webkit-text-fill-color: $red;
}

.form-group .error-icon {
  position: absolute;
  right: 0;
  bottom: 5px;
  display: none;
}

.form-group.error .error-icon {
  display: block;
}

.form-group.error .eye-icon {
  right: 45px;
}
