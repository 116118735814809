.modal-content.my-sisu-background,
.modal-content.my-sisu-background select {
  background-color: $my-sisu-background;
}

.my-sisu .form-group.radio {
  display: inline-block;
  margin: 0;
}

.my-sisu button {
  padding-right: 20px;
}

.my-sisu form {
  margin-bottom: 50px;
}

.my-sisu .radio-buttons-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px auto;
}

.my-sisu .form-radio:checked ~ .form-label::after {
  background-color: $orange;
  margin: 0;
}

.my-sisu .form-radio ~ .form-label::before {
  border-color: $dark-gray;
}

.my-sisu .radio-buttons-container label {
  font-size: 16px;
  line-height: 30px;
}

@media (max-width: 768px) {
  .my-sisu .radio-buttons-container .radio {
    margin-bottom: 15px;
  }

  .my-sisu .radio-buttons-container {
    margin-bottom: 25px;
  }

  .my-sisu button {
    font-size: 14px;
  }
}
