.signup-notice-modal.username-container {
  border: 2px solid $tickle-me-pink;
  display: inline-block;
  padding: 20px;
  margin-top: 40px;
}

.signup-notice-modal.username-container .username {
  display: inline-block;
  font-size: 18px;
  margin-top: 15px;
}

.signup-notice-modal.buttons-container {
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
}

.signup-notice-modal.buttons-container .btn,
.signup-notice-modal.buttons-container .btn ~ .btn {
  width: 49%;
  margin: 0;
}

@media (max-width: 768px) {
  .signup-notice-modal.buttons-container {
    justify-content: center;
  }

  .signup-notice-modal.buttons-container .btn,
  .signup-notice-modal.buttons-container .btn ~ .btn {
    width: 100%;
    margin-top: 10px;
  }
}
