.chosen-avatar {
  background: url("../images/spotlight.png") no-repeat center;
  background-size: auto;
  margin-top: -200px;
}

.chosen-avatar img {
  margin-top: 80px;
}

.chosen-avatar h2 {
  margin-bottom: 80px;
}

.school-name,
.school-year {
  text-transform: uppercase;
  font-size: 20px;
}

.school-year {
  font-weight: 500;
}

.account-disclaimer {
  font-size: 14px;
  margin: 0 auto 50px;
  text-align: center;
  width: 75%;
}
