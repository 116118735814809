.btn {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  max-width: 316px;
  height: 60px;
  padding: 21px 0;
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: none;
}

.btn.small {
  width: auto;
  height: auto;
  padding: 8px 20px;
  font-size: 12px;
}

.btn .hourglass-icon {
  position: absolute;
  top: 8px;
  right: 12px;
}

.btn[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
