.knowledge-area-slide-arrow-prev,
.knowledge-area-slide-arrow-next {
  position: absolute;
  top: 110px;
  width: 16px;
  height: 25px;
  color: $white;
  cursor: pointer;
  z-index: 2;
}

.knowledge-area-slide-arrow-prev {
  left: 20px;
}

.knowledge-area-slide-arrow-next {
  right: 20px;
}
