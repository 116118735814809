.manual-about,
.manual-my-books {
  width: 95%;
  margin: 0 auto;
  font-family: $montserrat;
}

.manual-about h3,
.manual-my-books h3 {
  text-transform: uppercase;
}

.manual-my-books {
  margin-bottom: 50px;
}

.manual-link {
  margin-bottom: 15px;
  position: relative;
}

.manual-link a {
  text-decoration: none;
}

.manual-link p {
  font-size: 24px;
  line-height: 70px;
}

.manual-link-arrow {
  position: absolute;
  right: 0;
  top: 25px;
  background-color: $purple;
  color: $white;
}

.bag-manual-about-container {
  margin: 0 auto;
  width: 70%;
  padding-top: 80px;
  text-align: center;
}

.bag-manual-about-container h1 {
  line-height: 30px;
}

.bag-manual-about-container p {
  color: $dark-gray;
  margin-bottom: 10px;
}

.bag-manual-about-container img {
  margin: 0 auto;
  display: block;
  height: 200px;
  padding: 40px 0;
}

.how-to-planet {
  padding-top: 40px;
}

.how-to-planet .tooltip {
  background-color: $dark-gray;
}

.how-to-planet .tooltip::before {
  border-color: $dark-gray transparent transparent;
}

.how-to-planet img {
  padding-top: 10px;
}

.book-footer {
  display: flex;
  justify-content: center;
  position: fixed;
  height: 50px;
  width: 100%;
  bottom: 0;
  background-color: $purple;
}

.book-footer-pages {
  color: $white;
  display: inline-block;
  font-size: 20px;
  line-height: 49px;
}

.book-pagination {
  display: flex;
  align-items: center;
}

.book-pagination .arrow-icon-left,
.book-pagination .arrow-icon-right {
  cursor: pointer;
}

.book-pagination .arrow-icon-left {
  margin-right: 25px;
}

.book-pagination .arrow-icon-right {
  margin-left: 25px;
}

.book-pagination .arrow-icon-left::before {
  transform: rotate(135deg) translateX(5px) translateY(-7px);
}

.book-container {
  width: 100%;
  max-width: 100%;
  overflow: scroll;
}

.book-container img {
  width: 70%;
  height: auto;
  margin: 0 auto;
  display: block;
  padding: 48px 0;
}

.book-reader-container {
  padding: 50px 0;
  text-align: center;
}

.book-scale {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

.book-zoom-out,
.book-zoom-in {
  background-size: 30px;
  background-repeat: no-repeat;
  color: $white;
  width: 30px;
  height: 30px;
  font-size: 40px;
  display: block;
  margin: 0 15px;
  cursor: pointer;
}

.book-zoom-out {
  background-image: url("../images/icon-zoom-out.svg");
}

.book-zoom-in {
  background-image: url("../images/icon-zoom-in.svg");
}

@media (max-width: 768px) {
  .bag-manual-about-container {
    width: 90%;
  }

  .bag-manual-about-container img {
    height: 100px;
    padding: 30px 0;
  }

  .book-container img {
    width: 100%;
  }

  .manual-link p {
    font-size: 14px;
    line-height: 50px;
  }

  .manual-link-arrow {
    top: 10px;
  }
}
