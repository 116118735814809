.document-modal {
  text-align: left;
}

.document-modal h3,
.document-modal p {
  color: $dark-gray;
}

.document-modal .formula {
  text-align: center;
}

.document-modal ul {
  padding-left: 20px;
}

.document-trail-image {
  width: 50%;
  padding-left: 25%;
}

.blue-table th {
  background-color: $regent-blue;
}

.document-modal .formula,
.blue-table td {
  background-color: $link-water-blue;
}

.orange-table th {
  background-color: $corvette;
}

.orange-table td {
  background-color: $oasis;
}
