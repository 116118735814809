.form-field {
  width: 100%;
  padding-bottom: 5px;
  box-sizing: border-box;
  border: solid $gray;
  border-width: 0 0 2px;
  outline: none;
  box-shadow: none;
  background-color: $white;
  font-family: $montserrat;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2em;
  color: $purple;
}

input.form-field {
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: $purple;
}

.form-field:focus,
.form-field:valid {
  border-color: $purple;
}

.form-input-link {
  position: absolute;
  right: 0;
  bottom: 10px;
  color: $purple;
  font-size: 16px;
  font-family: $khand;
}

.form-input-link + input.form-field {
  padding-right: 45px;
}
