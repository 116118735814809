.modal {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow-y: auto;
  padding: 50px 0 20px;
  z-index: 30;
}

.modal h3,
.modal h3,
.modal h4,
.modal h5 {
  font-family: "Montserrat", sans-serif;
}

.modal-content {
  background-color: $white;
  border-radius: 20px;
  padding: 30px 50px;
  margin: auto;
  box-sizing: border-box;
  width: 660px;
  max-width: 95%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.modal-content.small {
  width: 480px;
}

.modal-content .avatar {
  height: 200px;
  display: block;
  margin: 20px auto;
}

.modal-content .avatar.big {
  height: 800px;
  margin: 50px auto;
}

.modal-content .avatar + .btn {
  margin-top: 0;
}

.modal-content .btn {
  margin-top: 40px;
}

.modal-content .btn ~ .btn {
  margin-top: 20px;
}

.modal-content form {
  text-align: left;
}

.modal-content h2 {
  margin-top: 15px;
}

.modal-content h3 {
  margin-top: 20px;
}

.modal-content h2 + form,
.modal-content h3 + form {
  margin-top: 30px;
}

.modal-content .scrollable-container {
  height: 500px;
  overflow-y: scroll;
}
.modal-content * + h4 {
  margin-top: 30px;
}
.modal-content ol,
.modal-content ul {
  margin: 20px 0;
  font-size: 14px;
  line-height: 1.3;
}
.modal-content ul li,
.modal-content ol li {
  color: #959595;
}
.modal-content li + li {
  margin-top: 10px;
}
.modal-content h4 {
  color: #959595;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}

.modal-title {
  margin-bottom: 50px;
}

.modal-message {
  margin-top: 50px;
}

.modal-link {
  display: block;
  margin-top: 20px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: $purple;
  cursor: pointer;
}

.modal.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
}

@media (max-height: 675px) {
  .modal {
    justify-content: flex-start;
  }
}

@media (max-width: 700px) {
  .modal-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-content .avatar.big {
    max-width: 50%;
    height: auto;
  }
}
