select.form-field {
  padding-bottom: 5px;
  border-radius: 0;
  -webkit-appearance: none;
}

.form-select {
  position: relative;
}

.form-select .arrow-icon-down {
  position: absolute;
  bottom: 7px;
  right: 0;
  color: $dark-gray;
}

.form-field:focus ~ .arrow-icon-down,
.form-field:valid ~ .arrow-icon-down {
  color: $purple;
}

.form-group.error .arrow-icon-down {
  display: none;
}
