.user-profile-info-container {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 600px;
  margin: 50px auto 0;
}

.user-profile-info-container h3 {
  width: 100%;
  text-align: center;
}

.user-profile-info-container span {
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 20px;
  text-align: center;
}

.user-info {
  width: 50%;
  margin-bottom: 50px;
  text-align: center;
}

.change-info-form {
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
}

.change-info-form h3 {
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}

.edit-profile-success {
  color: $purple;
  text-align: center;
  margin-top: 10px;
}

.change-avatar-btn {
  margin-top: 30px;
}

.change-avatar-btn a {
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .user-info {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  .user-info p {
    margin-top: 0;
  }
}
