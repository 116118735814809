.sessions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
  color: $purple;
}

.app-name {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 500px;
  margin-bottom: 80px;
  text-align: center;
}

.session-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 245px;
  margin-bottom: 25px;
  width: 100%;
}

.session-link {
  color: $purple;
}

.session-link:hover,
.session-link:active {
  color: $orange;
}

@media (min-height: $large-height) {
  .sessions {
    justify-content: center;
    color: $white;
  }

  .sessions .btn-light {
    border: 2px solid $white;
    color: $white;
  }

  .session-link {
    color: $white;
  }
}

@media (max-width: 768px) {
  .app-name {
    width: 100%;
    margin-bottom: 80px;
  }
}
