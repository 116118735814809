.message-container {
  display: flex;
  overflow-y: auto;
  padding: 50px 0 20px;
  box-sizing: border-box;
  background-color: $purple;
  height: 100%;
  position: relative;
}

.message-content {
  margin: auto;
  width: 95%;
  text-align: center;
}

.message-content h2 {
  margin-bottom: 0;
}

.message-content p {
  color: $white;
  margin-bottom: 10px;
}

.message-content .avatar {
  height: 280px;
  margin: 20px 0;
}

.message-content .title-small {
  font-family: $khand;
  font-size: 25px;
}

.message-content .btn {
  margin-top: 10px;
}
