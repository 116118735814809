.form-radio {
  position: absolute;
  left: -9999999px;
}

.form-radio ~ .form-label {
  font-size: 14px;
  padding-left: 46px;
  line-height: 16px;
  cursor: pointer;
  pointer-events: all;
  display: flex;
  align-items: flex-start;
  position: relative;
  text-align: left;
}

.form-radio ~ .form-label::before,
.form-radio ~ .form-label::after {
  content: " ";
  transition: 0.2s ease all;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.form-radio ~ .form-label::before {
  top: 2px;
  left: 1px;
  width: 22px;
  height: 22px;
  border-style: solid;
  border-color: $purple;
  border-width: 2px;
  z-index: 1;
}

.form-radio ~ .form-label::after {
  top: 6px;
  left: 5px;
  width: 18px;
  height: 18px;
  border-width: 0;
}

.form-radio ~ .form-label img {
  float: left;
  margin-right: 15px;
}

.form-radio ~ .form-label span {
  color: $main-color;
}

.form-radio:checked ~ .form-label::after {
  background-color: $purple;
}
