.btn-light {
  background-color: transparent;
  border: 2px solid $purple;
  color: $purple;
  position: relative;
}

.btn-light:hover,
.btn-light:active,
.btn-light:focus {
  border-color: $btn-primary-color !important;
  color: $btn-primary-color !important;
}

.purple .btn-light {
  border-color: $white;
  color: $white;
}

.btn-light.small {
  text-align: left;
  font-size: 12px;
  width: 160px;
}
